/**
 *  Bootstrap the application when the jQuery ready event is fired.
 */
class Way2web {
    /**
     * Initialize all the different components.
     */
    constructor() {
        this.initLegacyFunctions();

        $('.sortable').sortable();
        $('.info-tooltip, .f-error').tooltip();

        Components.overviewSearch();
        Components.initDatepickers();
        Components.initWYSIWYG();

        w2wDatatables.onDomReady();
        w2wDoConfirm.onDomReady();
    }

    /**
     * Bind the legacy functions to components.
     */
    initLegacyFunctions() {
        window.confURL = Components.redirectAfterConfirmed;
        window.btnSwitch = Components.toggleButtonActive;
        window.selDoc = Components.redirectToDocument;
        window.selTemplate = Components.redirectToTemplate;
        window.selType = Components.redirectToType;
    }
}

$(document).ready(() => {
    window.Way2web = new Way2web();
});
