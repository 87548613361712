/**
 * All the default Way2Web components.
 */
window.Components = class Components {
    /**
     * Redirect to a URL after a user confirmed the dialog.
     *
     * @param {string} url
     * @param {string} message
     */
    static redirectAfterConfirmed(url, message) {
        if (confirm(message) == true) {
            location.href = url;
        }
    }

    /**
     * Search and change the value of an input based on a given id.
     *
     * @param {string} elementId
     */
    static toggleButtonActive(elementId) {
        const element = $('#' + elementId);
        const currentValue = element.val();
        let newValue = 0;

        if (currentValue == 0) {
            newValue = 1;
        }

        $(element).val(newValue);
    }

    /**
     * Redirect to a document.
     */
    static redirectToDocument() {
        var url = '/cms/document/' + $('#doc_id').val();

        location.href = url;
    }

    /**
     * Redirect to the template upload page for a document.
     */
    static redirectToTemplate() {
        var url = '/cms/templates/' + $('#doc_id').val();

        location.href = url;
    }

    /**
     * Redirect to a document type.
     */
    static redirectToType() {
        var url = '/cms/readfirst/' + $('#type_id').val();

        location.href = url;
    }

    /**
     * Initialize all the different datepickers.
     */
    static initDatepickers() {
        $('#dpPermit').datepicker();
        $('#dpInternshipVan, #dpInternshipVan input')
            .datepicker()
            .on('hide', e => {
                this.checkIfInternshipStartsTooSoon(e.date);
            });

        $('#dpInternshipTot, #dpInternshipTot input').datepicker();
    }

    /**
     * Check if an internship starts too soon.
     * @param {Date} date
     * @return {boolean}
     */
    static checkIfInternshipStartsTooSoon(date) {
        if (this.isBeforeDateByAmountOfDays(date, 91)) {
            return $('#dpStartTimeframeWarning').removeClass('hidden');
        } else {
            return $('#dpStartTimeframeWarning').addClass('hidden');
        }
    }

    /**
     * Check whether a date is before a certain amount of days from now
     * @param {Date} date
     * @param {int} days
     * @returns {boolean}
     */
    static isBeforeDateByAmountOfDays(date, days) {
        const dateInMilliseconds = date.getTime();
        const futureDateInMilliseconds = new Date().getTime() + Math.ceil(days * 86400000);

        return dateInMilliseconds <= futureDateInMilliseconds;
    }

    /**
     * Autocompleter for the overview page.
     */
    static overviewSearch() {
        var arrSource = '';
        var zknSource = '';

        const appZoeken = $('#zknWaarde').typeahead({
            source: arrSource
        });

        $('#zknWaarde').keyup(function() {
            var zknUrl = 'overview/search/' + $('#zknWaarde').val();

            $.get(zknUrl, function(data) {
                zknSource = data;
            });

            arrSource = zknSource.split('|');
            appZoeken.data('typeahead').source = arrSource;
        });
    }

    /**
     * Attach summernote to the textareas.
     */
    static initWYSIWYG() {
        var toolbar = [
            ['style', ['bold', 'italic', 'underline', 'clear']],
            ['font', ['strikethrough', 'superscript', 'subscript']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['insert', ['link']],
            ['misc', ['undo', 'redo']],
            ['codeview', ['codeview']]
        ];

        Way2web.summernote = [];
        $('.js-wysiwyg').each(function() {
            Way2web.summernote.push(
                new W2Summernote()
                    .element($(this))
                    .toolbar(toolbar)
                    .init()
            );
        });
    }
};
