/**
 * doConfirm
 *
 * This is an awesome js script that makes confirming an action a breeze
 * It grabs the action from a link or button and does it once it has been confirmed
 *
 * @author Johan van Helden <mail@johanvanhelden.com>
 * @version 1.0
 */
(function(w2wDoConfirm) {
    'use strict';

    w2wDoConfirm.init = function() {
        /* set default values */
        var sElem = $(this);
        var targetURl = '';
        var message = 'No message has been set';
        var title = 'Confirm';
        var actionButtonText = 'Confirm';
        var closeButtonText = 'Cancel';
        var modalID = 'confirm_window';
        var autoFocusConfirm = true;
        var modalConfirm;

        /* if the element has a href use this, otherwise use the data-url attribute */
        if (sElem.attr('href') != '' && sElem.attr('href') != '#' && sElem.attr('href') != undefined) {
            targetURl = sElem.attr('href');
        } else {
            targetURl = sElem.attr('data-url');
        }

        /* if the element has data attributes that overwrite the default onces, set them */
        if (sElem.attr('data-msg') != '' && sElem.attr('data-msg') != undefined) {
            message = sElem.attr('data-msg');
        }

        if (sElem.attr('data-title') != '' && sElem.attr('data-title') != undefined) {
            title = sElem.attr('data-title');
        }

        if (sElem.attr('data-actionButtonText') != '' && sElem.attr('data-actionButtonText') != undefined) {
            actionButtonText = sElem.attr('data-actionButtonText');
        }

        if (sElem.attr('data-closeButtonText') != '' && sElem.attr('data-closeButtonText') != undefined) {
            closeButtonText = sElem.attr('data-closeButtonText');
        }

        if (sElem.attr('data-modalID') != '' && sElem.attr('data-modalID') != undefined) {
            modalID = sElem.attr('data-modalID');
        }

        if (sElem.attr('data-autoFocusConfirm') != '' && sElem.attr('data-autoFocusConfirm') != undefined) {
            autoFocusConfirm = sElem.attr('data-autoFocusConfirm');
        }

        /* build the modal html */
        modalConfirm =
            '\
            <div id="' +
            modalID +
            '" class="modal hide fade" tabindex="-1" role="dialog" aria-labelledby="sendModalLabel" aria-hidden="true">\
                <div class="modal-header">\
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>\
                    <h3>' +
            title +
            '</h3>\
                </div>\
                <div class="modal-body">\
                    <p style="font-weight:normal;">' +
            message +
            '</p>\
                </div>\
                <div class="modal-footer">\
                    <a href="' +
            targetURl +
            '" class="btn blue default-btn-action" tabindex="0">' +
            actionButtonText +
            '</a>\
                    <button type="button" class="btn btn-link" data-dismiss="modal">' +
            closeButtonText +
            '</button>\
                </div>\
            </div>\
        ';

        sElem.click(function(event) {
            event.preventDefault();

            /* add html to the body and show the modal */
            $('body').append(modalConfirm);
            $('#' + modalID).modal('show');

            if (autoFocusConfirm == true) {
                /* once the modal is visible, focus the default button */
                $('#' + modalID).on('shown.bs.modal', function() {
                    $('#' + modalID + ' .default-btn-action').focus();
                });
            }

            /* once the modal is closed, removed it from the DOM */
            $('#' + modalID).on('hidden.bs.modal', function() {
                $('#' + modalID).remove();
            });
        });
    };

    w2wDoConfirm.onDomReady = function() {
        $('.confirm').each(w2wDoConfirm.init);
    };
})((window.w2wDoConfirm = window.w2wDoConfirm || {}));
