/*
    w2wDatatables
    Make dataTables better.

    Usage:
    - making a table a dataTable:
        - add the class js-dataTable to the <table>
    - changing the assets folder:
        - change the w2wDatatables.assetsPath option
    - default column sorting:
        - Set default sorting with class: dataTable-defaultSort on the <th>
        - Set default sorting direction with data attribute: data-sortDirection="desc" on the <th>

    Everything will work better now.
*/
(function(w2wDatatables) {
    'use strict';

    /* configuration */
    w2wDatatables.assetsPath = '/wp-content/themes/way2web/assets';
    w2wDatatables.debugging = false;
    w2wDatatables.defaultSortCol = false;

    w2wDatatables.setDefaultSortCol = function(currentTable) {
        var defaultSortCol = currentTable.find('th.dataTable-defaultSort');
        var defaultSortColIndex = defaultSortCol.index();
        var defaultSortDirection;

        if (defaultSortColIndex >= 0) {
            defaultSortDirection = defaultSortCol.attr('data-sortDirection');

            if (defaultSortDirection !== 'desc') {
                defaultSortDirection = 'asc';
            }

            w2wDatatables.defaultSortCol = [defaultSortColIndex, defaultSortDirection];
        } else {
            w2wDatatables.defaultSortCol = [1, 'desc'];
        }
    };

    w2wDatatables.initDataTables = function() {
        $('.js-dataTable').each(function() {
            var currentTable = $(this);

            /* see if we would like to sort a column by default */
            w2wDatatables.setDefaultSortCol(currentTable);

            /* actually make the table */
            // eslint-disable-next-line
            currentTable.DataTable({
                dom: 'T<"clearfix">lfrtip',
                order: [w2wDatatables.defaultSortCol],
                tableTools: {
                    sSwfPath: w2wDatatables.assetsPath + '/swf/copy_csv_xls.swf',
                    aButtons: ['csv']
                }
            });
        });
    };

    w2wDatatables.onDomReady = function() {
        w2wDatatables.initDataTables();
    };
})((window.w2wDatatables = window.w2wDatatables || {}));
